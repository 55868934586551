var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"font-weight-bold pb-1 primary--text"},[_vm._v("Gestión")]),_c('h5',{staticClass:"font-weight-light pb-3"},[_vm._v("Gestión de mandantes")]),_c('v-card',{staticClass:"elevation-2",attrs:{"id":"cardborde"}},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","top":"","color":"verde"}}),_c('v-card-title',{staticClass:"font-weight-light"},[_vm._v(" Lista de Mandantes "),_c('v-spacer'),_c('v-text-field',{staticClass:"m-2",attrs:{"append-icon":"mdi-magnify","color":"primary","label":"Buscar","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 verde primary--text",on:{"click":function($event){return _vm.$router.push({ name: 'mandante' })}}},[_vm._v("Nuevo Mandante")])],1),_c('v-tabs',{attrs:{"right":""}},[_c('v-tab',{on:{"click":function($event){_vm.bEstado = true}}},[_vm._v("Activos")]),_c('v-tab',{on:{"click":function($event){_vm.bEstado = false}}},[_vm._v("Inactivos")])],1),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"no-results-text":_vm.noresult,"no-data-text":"Sin resultados que mostrar","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"footer-props":{
        'items-per-page-text': 'Resultados por página',
        'items-per-page-all-text': 'Todos',
      }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"item.url",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.verURL(item.url)}}},[_vm._v(" mdi-web ")])]}},{key:"item.accion",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"mr-2 p-1",attrs:{"color":"primary","text-color":"verde","small":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item._id)}}},[_vm._v(" mdi-pencil ")])],1),_c('v-chip',{staticClass:"mr-2 p-1",attrs:{"color":"primary","text-color":"red","small":""}},[(_vm.usuarioDB.data.role == 'ADMIN')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item._id)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}},{key:"item.activo",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getEstados(item.activo),"dark":"","outlined":""}},[_vm._v(" mdi-"+_vm._s(_vm.getEstadosTexto(item.activo))+" ")])]}}])})],1),_c('v-snackbar',{attrs:{"color":"green","dark":"","timeout":"1500","align":"center"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" URL copiada exitosamente! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }